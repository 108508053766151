import { SceneComponent, ISceneNode, ComponentInteractionType } from "../../SubSystems/sceneManagement/SceneComponent";
import Simulation from '../../SubSystems/core/Simulation';
import InputSubSystem from "../../SubSystems/input/InputSubSystem";

export enum GenericInputEvent {
    DragBegin = 'DRAG_BEGIN',
    Drag = 'DRAG',
    DragEnd = 'DRAG_END',
    Key = 'KEY',
    Scroll = 'SCROLL',
    Click = 'CLICK',
    PointerMove = 'POINTER_MOVE',

};

type Events = {
    [GenericInputEvent.DragBegin]: boolean,
    [GenericInputEvent.Drag]: boolean,
    [GenericInputEvent.DragEnd]: boolean,
    [GenericInputEvent.Key]: boolean,
    [GenericInputEvent.Scroll]: boolean,
    [GenericInputEvent.Click]: boolean,
  };

export class GenericInput extends SceneComponent {
    //public members
    inputNode: ISceneNode;

    //private members
    private inputComponent:SceneComponent;

    events: Events = {
        [GenericInputEvent.DragBegin]: true,
        [GenericInputEvent.Drag]: true,
        [GenericInputEvent.DragEnd]: true,
        [GenericInputEvent.Key]: true,
        [GenericInputEvent.Scroll]: true,
        [GenericInputEvent.Click]: true,
      };

    constructor()
    {
        super();
    }

    onEvent(eventType: string, eventData: unknown) {
        console.log('cam move', InputSubSystem?.input?.getMatterPortCameraMovement());

        var root = this.context;
        var eventDataWithMetaData = {
            event: eventData,
            component: this,
            node: root,
            interactionType: eventType
        }
        switch(eventType) {
            case GenericInputEvent.DragBegin:
                this.notify(GenericInputEvent.DragBegin, eventDataWithMetaData);
                // console.log(eventDataWithMetaData);
                // Simulation.instance.propertiesPanel.hidePropertiesPanel();
                break;
            case GenericInputEvent.DragEnd:
                this.notify(GenericInputEvent.DragEnd, eventDataWithMetaData);
                break;
            case GenericInputEvent.Key:
                this.notify(GenericInputEvent.Key, eventDataWithMetaData);
                break;
        }
    }

    async setupInputNode(targetNode:ISceneNode) {
        this.inputNode = targetNode;
        this.inputComponent = this.inputNode.addComponent('mp.input', {
            eventsEnabled: true,
            userNavigationEnabled: false,
            unfiltered: false,
        }) as SceneComponent;
        this.bindEvent(GenericInputEvent.DragBegin, this.inputComponent, ComponentInteractionType.DRAG_BEGIN);
        this.bindEvent(GenericInputEvent.DragEnd, this.inputComponent, ComponentInteractionType.DRAG_END);
        this.bindEvent(GenericInputEvent.Key, this.inputComponent, ComponentInteractionType.KEY);
        targetNode.start();
    }

    setMatterPortCameraMovement(x:boolean) {
        if(this.inputComponent) {
            console.log(`[st] setMatterPortCameraMovement 2 ${x}`);
            this?.inputComponent?.inputs && (this.inputComponent.inputs!.userNavigationEnabled = x);
        }
    }

    getMatterPortCameraMovement() {
        return this.inputComponent.inputs?.userNavigationEnabled;
    }

}

export const genericInputType = 'generic.input';
export const makeGenericInput = function () {
    return new GenericInput();
};
