import * as THREE from 'three';
import { EulerIntegrator, eulerIntegratorType } from '../../components/systemComponents/EulerIntegrator';
import { ISceneNodeExtensions } from '../../extensions/ISceneNodeExtensions';
import { QuaternionExtensions } from '../../extensions/QuaternionExtensions';
import { AddObjectClickSpy } from '../../spies/AddObjectClickSpy';
import {
    ChangeBooleanPropertyPassThrough,
    ChangeNodeColorPassThrough,
    ChangeTextPanelTextPassThrough, ChangeTextPassThrough,
} from '../../Tools/InteropTypes/InteropClasses';
import Utils from '../../Tools/Utils';
import { ComponentInteractionType, ISceneNode, SceneComponent } from '../sceneManagement/SceneComponent';
import { SceneLoader } from '../sceneManagement/SceneLoader';
import {
    UserDataTypes,
    UserDataProperties,
    NodeDataProperties,
    CompatabilityUserDataTypes, UnserializedUserData,
} from '../ui-interop/PropertiesPanel';
import { AuthUser } from '../../../../../types/models/AuthUser';
import {
    outlinePostProcessType,
    TemporalOutlineElement,
} from '../../components/PostProcess/OutlineComponent';
import { PlaneRenderer } from '../../components/meshComponents/basic/PlaneRenderer';
import { SimulationMode } from './RenderingAndPlaceObjectStateSystem';
import InputSubSystem from '../input/InputSubSystem';
import NodeStorage from '../storageAndSerialization/NodeStorage';
import PropertiesPanelWithSimulationFunctionality from '../ancillary/PropertiesPanelWithSimulationFunctionality';
import { SpaceData, TagGroup } from 'types/models/home/HomeApp';
import { Behaviors, ITransform } from './Behaviors';
import {
    TriggerActionOutcome,
    VariableLogicType,
    VariableValueTriggerPair,
} from '../ui-interop/PropertiesPanelBehaviorActions';
import { store } from 'App';
import { RotateToggle } from '../../components/tiny/RotateToggle';
import { ToggleComponent } from '../../components/tiny/ToggleComponent';
import { ImageRenderer } from '../../components/meshComponents/basic/ImageRenderer';
import { GlowHoverSpy } from '../../spies/GlowHoverSpy';
import _ from 'lodash';
// <<<<<<< HEAD
import { showGizmo } from 'redux/actions/ThreeD';
import { setSpaceModelsList } from 'redux/actions/Home';
// import { FlowComponent } from '../../components/FlowComponent';
import PoseResetOnInit from '../ancillary/PoseResetOnInit';
import { rootThreeContextType } from '../../components/systemComponents/ThreeContext';
// =======
import { FlowComponent } from '../../components/AdditionComponents/FlowComponent';
import { SceneNode } from '../../../../../CustomSdk/Mimick/SceneNode';
import { ImageRendererComponent } from '../../../../../CustomSdk/Components/ImageRendererComponent';
import { RotateToggleComponent } from '../../../../../CustomSdk/Components/RotateToggleComponent';
import { areNodesSpatialThinkSDK } from '../../../../../modules/home/SpaceDetail/utils';
import { ArrowFlowComponent } from 'CustomSdk/Components/ArrowFlowComponent';
import { SpatialThinkSDK, SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import TextureManager from '../TextureManager';
import { TextBoxComponent } from 'CustomSdk/Components/TextBoxComponent';
import { AnalogGaugeComponent } from '../../components/AnalogGaugeComponent';
import { DragBeginObjectSpy, DragEndObjectSpy, DragObjectSpy } from '../../spies/DragSpies';
import { WireTerminal } from '../../components/Wire/WireTerminal';
import { WireComponent } from '../../components/Wire/WireComponentOld';
import { HoverSpy } from '../../spies/HoverSpy';
import UndoStack from '../storageAndSerialization/UndoStack';

export enum ExternalSdkMode {
    MP = 'MP'
}
export default class Simulation extends PropertiesPanelWithSimulationFunctionality {
    private static _instance: Simulation | null = null;

    sdkMode: SpatialThinkSDKMode | ExternalSdkMode;
    currentUser: AuthUser;
    currentSpace: SpaceData;

    colliderNodeMap = new Map();
    componentNodeMap = new Map();
    //public members

    integrator: EulerIntegrator;
    spaceID: string;
    private initializationComplete: boolean;
    public sdk: any = null;
    // public mpRaycaster: MPRaycaster;
    private originalMaterials: Map<string, THREE.Material | THREE.Material[]> = new Map();
    public xKeyPressed: boolean = false;
    public undoStack: UndoStack;

    private constructor() {
        super();
        // this.variables = [];
        this.initializationComplete = false;
        console.log('[Simulation] [sdk] started');
    }

    public static ForceReset(): void {
        Simulation._instance = null;
        TextureManager.release();
        Simulation._instance = new Simulation();
    }

    public stopMPSDK(): void {
        !areNodesSpatialThinkSDK() && this.sceneLoader?.stopAllNodes();
        this.renderingSubSystem?.destroy();
        Simulation.instance.sdk = null;
        Simulation.ForceReset();
        console.log('[st] [sdk] stopped MpSdk renderer', this.renderingSubSystem);//TODO-ST does Mp need to be destroyed ?
    }

    public stopSTSDK(): void {
        areNodesSpatialThinkSDK() && this.sceneLoader?.stopAllNodes();
        (Simulation.instance.sdk as SpatialThinkSDK)?.destroy();
        Simulation.instance.sdk = null;
        Simulation.ForceReset();
        let arButton = document.getElementById('ARButton');
        arButton?.remove();
        arButton && document.removeChild(arButton);
        let vrButton = document.getElementById('VRButton');
        vrButton?.remove();
        console.log('[st] [sdk] stopped ST Sdk renderer', this.renderingSubSystem); //TODO-ST is this enough to destroy AR VR ?
    }

    public static get instance(): Simulation {
        if (!Simulation._instance) {
            Simulation._instance = new Simulation();
        }

        return Simulation._instance;
    }

    async initialize(spaceID: string, sdk: any, authUser: AuthUser, sdkMode: SpatialThinkSDKMode | ExternalSdkMode
        // currentSpace: SpaceData
    ) {
        console.log('[st] Initializing sdk');

        let currentSpace = store.getState().home.currentSpace;
        if (!currentSpace) {
            return;
        }

        this.spaceID = spaceID;
        this.currentUser = authUser;
        this.currentSpace = currentSpace;
        console.log('[Simulation] [st] Base setup...');

        this.renderingSubSystem.initialize(sdk);
        this.sceneLoader = new SceneLoader(sdk, this);
        this.sceneLoader.load('light', () => {
        });

        console.log('[Simulation] [st] Base setup complete!');

        this.simulationMode = SimulationMode.NONE;
        this.sdkMode = sdkMode;
        // this.sdk = sdk;

        if (areNodesSpatialThinkSDK()) {

        } else {
            const eulerIntegratorNode = await sdk.Scene.createNode();
            this.integrator = eulerIntegratorNode.addComponent(eulerIntegratorType);
            eulerIntegratorNode.start();
            this.setupPointerIntersectionSubscription();

            this.undoStack = new UndoStack();

            //Needed for global access of three.js context
            const threeContextNode = await sdk.Scene.createNode();
            threeContextNode.addComponent(rootThreeContextType);
            threeContextNode.start();

            InputSubSystem.instance.Initialize(sdk);

            this.InitializeCamera();
            console.log('===========================================================');


            const postProcessNode = await sdk.Scene.createNode();
            this.outlineComponent = await postProcessNode.addComponent(outlinePostProcessType);
            await postProcessNode.start();

            const postProcessNode2 = await sdk.Scene.createNode();
            this.outlineComponentColor2 = await postProcessNode2.addComponent(outlinePostProcessType);
            await postProcessNode2.start();

            this.outlineComponentColor2.outlinePass?.visibleEdgeColor.set(0xffff00);
            this.outlineComponentColor2.outlinePass?.hiddenEdgeColor.set(0xffff00);

            // this.mpRaycaster = new MPRaycaster();
            //let gp = new GlitchPass();

            //this.renderingSubSystem.effectComposer.addPass( gp );
        }

        // initialize fixed nodes from DB
        console.log('[st] [sdk] Loading Nodes...');
        let spaceModels = await NodeStorage.loadNodesFromDB(this.scenePreProcess.bind(this));
        console.log('[st] [sdk] Loading Nodes complete! ' + Array.from(spaceModels.keys()).length);

        //TODO: UNCOMMENT THIS TO MAKE OTHER VARIABLES WORK
        store.dispatch(setSpaceModelsList(spaceModels));
        console.log('[st] [sdk] spacemodels dispatched from sim!');
        this.initializationComplete = true;

        if (areNodesSpatialThinkSDK()) {

        } else {
            EulerIntegrator.instance?.integrators.push(this.tickTok.bind(this));
        }
    }

    public updateVariablesState() {
        let newState = store.getState().layer.variableValues;
        this.spaceModels()?.forEach(model => {

            let node: ISceneNode | SceneNode;
            if (!areNodesSpatialThinkSDK()) {
                node = model.nodeRef as ISceneNode;
            } else {
                node = model.nodeRef as SceneNode;
            }

            if (node) {
                if (node.userData && UserDataProperties.inputSource1 in node.userData) {
                    if ((node.userData[UserDataProperties.inputSource1] as string) && (node.userData[UserDataProperties.inputSource1] as string).length > 0) {
                        // console.log(`[st] [vars] [flow] new state`, newState);
                        let localSystemVariable = newState?.find(element => (element.name as string).toLowerCase() === (node.userData[UserDataProperties.inputSource1] as string).toLowerCase());
                        if (localSystemVariable) {
                            let allowedValuesArray = (localSystemVariable.values as string)?.split(',').map(x => x.trim());
                            if (localSystemVariable.value) {
                                let stateIndex = allowedValuesArray.indexOf(localSystemVariable.value);

                                if (node.name === 'On Off Button' || node.name === "On Off Switch") {
                                    if (!areNodesSpatialThinkSDK()) {
                                        let toggleMultiModelComponent: ToggleComponent = (node.components[3] as any) as ToggleComponent;
                                        if (toggleMultiModelComponent) {

                                            if (stateIndex == 0) {
                                                // if (!toggleMultiModelComponent.inputs.toggle) {
                                                console.log(`[vars] updating toggle for ${node.userData?.nameToShow} from ${toggleMultiModelComponent.inputs.toggle} to true '`);
                                                toggleMultiModelComponent.inputs.toggle = true;
                                                // }
                                            } else {
                                                // if (toggleMultiModelComponent.inputs.toggle) {
                                                console.log(`[vars] updating toggle for ${node.userData?.nameToShow} from ${toggleMultiModelComponent.inputs.toggle} to false'`);
                                                toggleMultiModelComponent.inputs.toggle = false;
                                                // }
                                            }
                                        }
                                    } else {
                                        //TODO-ST
                                    }
                                } else if (node.name.includes('Lever') || node.name.includes('Dial')) {
                                    if (!areNodesSpatialThinkSDK()) {
                                        let rotateToggle: RotateToggle = (node.components[1] as any) as RotateToggle;
                                        // let rotateToggle: RotateToggle = ((node as ISceneNode).components[1] as any) as RotateToggle;
                                        // rotateToggle.setNextInputValue();
                                        if (rotateToggle && rotateToggle.inputs.state !== stateIndex) {
                                            console.log(`[vars] updating rotate toggle for ${node.userData?.nameToShow} from ${rotateToggle.inputs.state} to ${stateIndex} '`);
                                            rotateToggle.inputs.state = stateIndex;
                                        }
                                    } else {
                                        let rotateToggle: RotateToggleComponent = ((node as SceneNode).customComponents[0] as any) as RotateToggleComponent;
                                        if (rotateToggle && rotateToggle.inputs.state !== stateIndex) {
                                            console.log(`[vars] updating rotate toggle for ${node.userData?.nameToShow} from ${rotateToggle.inputs.state} to ${stateIndex} '`);
                                            rotateToggle.inputs.state = stateIndex;
                                        }
                                    }
                                } else if ((node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) || (node.userData[UserDataProperties.type] === UserDataTypes.analogGauge)) {
                                    console.log(`[vars] updating iot for ${node.userData?.nameToShow} to ${localSystemVariable.value} '`);
                                    var iot = Utils.GetNestThermostatComponent(node);
                                    iot?.activate(node.userData);

                                    // } else if ((node.userData[UserDataProperties.type] === UserDataTypes.multimeter)) {
                                    //     console.log(`[vars] updating multimeter for ${node.userData?.nameToShow} to ${localSystemVariable.value} '`);
                                    //     // var multimeterText =
                                    //     (node.components[9] as any).inputs.text = localSystemVariable.value;
                                    //     // iot?.activate(node.userData);

                                } else if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
                                    console.log(`[vars] updating text for ${node.userData?.nameToShow} to ${localSystemVariable.value} '`);
                                    if (!areNodesSpatialThinkSDK()) {
                                        Simulation.instance.propertiesPanel.changeTextOfNode(
                                            new ChangeTextPanelTextPassThrough(
                                                localSystemVariable.value, Utils.GetUD_NumberValue(node.userData, UserDataProperties.fontSize), null, node, false,
                                            ));
                                    } else {
                                        ((node as SceneNode).customComponents[0] as TextBoxComponent).inputs.text = localSystemVariable.value;
                                    }
                                } else if (node.userData[UserDataProperties.type] === UserDataTypes.arrowFlow) {
                                    console.log(`[vars] [flow] updating var ${localSystemVariable.name} for arrow flow for ${node.userData?.nameToShow} to ${localSystemVariable.value} ', stateIndex is ${stateIndex}, activate is ${!!(stateIndex == 0)}`);
                                    if (!areNodesSpatialThinkSDK()) {
                                        ((node.components[3] as any) as FlowComponent).inputs.activate = !!(stateIndex == 0)
                                    } else {
                                        ((node as SceneNode).customComponents[0] as ArrowFlowComponent).inputs.activate = !!(stateIndex == 0);
                                    }
                                }

                            }
                        }
                    }
                }
            }
        });
        // this.variables = JSON.parse(JSON.stringify(newSt ate));
    }

    public processNodeClickOutsideLesson(node: ISceneNode | SceneNode) {

        if (node.name === 'On Off Button' || node.name === "On Off Switch") {
            if (!areNodesSpatialThinkSDK()) {
                let toggleMultiModelComponent: ToggleComponent = ((node as ISceneNode).components[3] as any) as ToggleComponent;
                if (toggleMultiModelComponent) {
                    toggleMultiModelComponent.setNextInputValue();
                }
            } else {
                // let toggleMultiModelComponent: ToggleComponent = (node.components[3] as any) as ToggleComponent;
                // if (toggleMultiModelComponent) {
                //     toggleMultiModelComponent.setNextInputValue();
                // }

            }
        } else if (node.name.includes('Lever') || node.name.includes('Dial')) {
            if (!areNodesSpatialThinkSDK()) {
                let rotateToggle: RotateToggle = ((node as ISceneNode).components[1] as any) as RotateToggle;
                rotateToggle.setNextInputValue();
            } else {
                let rotateToggle: RotateToggleComponent = ((node as SceneNode).customComponents[0]) as RotateToggleComponent;
                rotateToggle.setNextInputValue();
            }
        } else if ((node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) || (node.userData[UserDataProperties.type] === UserDataTypes.analogGauge)) {

            // var iot = Utils.GetNestThermostatComponent(node);
            // iot?.setNextInputValue();
        } else if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
            if (!areNodesSpatialThinkSDK()) {
                let textComponents = Utils.GetAllCanvasTextComponents((node as ISceneNode));
                console.log(`[st] [textnode] clicked`);
                if (textComponents && textComponents.length > 0) {
                    console.log(`[st] [textnode] `, textComponents[0].inputs);
                }
            } else {

            }

            // console.log(`[vars] updating text for ${node.userData?.nameToShow} to ${localSystemVariable.value} '`)
            // Simulation.instance.propertiesPanel.changeTextOfNode(
            //     new ChangeTextPanelTextPassThrough(
            //         localSystemVariable.value, Utils.GetUD_NumberValue(node.userData, UserDataProperties.fontSize), node, false,
            //     ));
            // textComponents[0].inputs.text = param.newText;
        } else if (node.userData[UserDataProperties.type] === UserDataTypes.arrowFlow) {
            if (!areNodesSpatialThinkSDK()) {
                (((node as ISceneNode).components[3] as any) as FlowComponent).setNextInputValue()
            } else {
                let arrowFlowComponent = (((node as SceneNode).customComponents[0]) as ArrowFlowComponent);
                arrowFlowComponent.inputs.activate = !arrowFlowComponent.inputs.activate;
            }
        }
    }

    hideGizmo() {
        // if (GizmoTools?.instance) {
        //     GizmoTools?.instance?.hideTools();
        // }
        this.sceneLoader?.hideTransformGizmo();
        store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
    }


    tickTok(dt: number) {
        // if (!this.propertyPanelShowOverride[0]) {
        //     if (store.getState().threeD.showGizmo) {
        //         store.dispatch(showGizmo(false));
        // Simulation.instance.propertiesPanel.hidePropertiesPanel();
        // }


        // }
        //console.log(this.variables![0])
        //this.variables?.push({"name" : "aaa", "values" : "a, b"})
    }

    evaluateVariableTriggerConditions(variableValues: any[]): void {
        if (this.spaceModels()) {
            this.spaceModels().forEach(model => {
                let targetNode: ISceneNode = model.nodeRef;//
                if (targetNode) {
                    if (UserDataProperties.TriggerActionList in targetNode.userData) {
                        //let varTriggerList: VariableValueTriggerPair[] = model.nodeRef.userData[UserDataProperties.varTriggers] || [];
                        let varTriggerList: VariableValueTriggerPair[] = targetNode.userData[UserDataProperties.varTriggers] || [];

                        let allVariableTriggerConditionsMet: boolean = false;

                        for (const varTrigger of varTriggerList) {
                            let relevantSystemVariable = variableValues.find(vv => vv.name === varTrigger.name);

                            if (relevantSystemVariable) {
                                if (relevantSystemVariable.hasOwnProperty('value')) {
                                    if ((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
                                        if (varTrigger.hasOwnProperty('logic')) {
                                            if (varTrigger.logic as VariableLogicType === VariableLogicType.and) {
                                                if (relevantSystemVariable.value === varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                } else {
                                                    allVariableTriggerConditionsMet = false;
                                                    break;
                                                }
                                            } else if (varTrigger.logic as VariableLogicType === VariableLogicType.or) {
                                                if (relevantSystemVariable.value === varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                    break;
                                                }
                                            } else if (varTrigger.logic as VariableLogicType == VariableLogicType.blank) {
                                                if (relevantSystemVariable.value != varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                    break;
                                                } else {
                                                    allVariableTriggerConditionsMet = false;
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (relevantSystemVariable.value === varTrigger.value) {
                                                allVariableTriggerConditionsMet = true;
                                            } else {
                                                allVariableTriggerConditionsMet = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let triggerActionListArray = targetNode.userData[UserDataProperties.TriggerActionList] as TriggerActionOutcome[];
                        if (allVariableTriggerConditionsMet) {

                            for (const triggerAction of triggerActionListArray) {
                                Behaviors.runActionsOnNode(targetNode, triggerAction);
                            }
                        } else {
                            for (const triggerAction of triggerActionListArray) {
                                Behaviors.runUnActionsOnNode(targetNode, triggerAction);
                            }
                        }
                    }
                }
            });

            //Knock yerself out with performance benchmarks
            // if(this.spaceModels().size > 0) {
            //     //let spaceModelsArray = Array.from(this.spaceModels().values());
            //     var testStore = null;
            //     performance.mark("T0");
            //     for (const iterator of this.spaceModels()) {
            //         //console.log(iterator[1]);
            //         testStore = iterator[1];
            //         console.log(testStore)
            //     }

            //     performance.mark("T1");
            //     this.spaceModels().forEach(model => {
            //         testStore = model;
            //         console.log(testStore)
            //     });

            //     performance.mark("T2");
            //     let spaceModelsArray = Array.from(this.spaceModels().values());

            //     _.forEach(spaceModelsArray, function(element) {
            //         testStore = element;
            //         console.log(testStore)
            //     } )

            //     performance.mark("T3");
            //     for(let key of this.spaceModels().keys()) {
            //         testStore = this.spaceModels().get(key);
            //         console.log(testStore)
            //     }
            //     performance.mark("T4");

            //     performance.measure("For of perf", "T0", "T1");
            //     performance.measure("Foreach perf ts native", "T1", "T2");
            //     performance.measure("Foreach  lodash/array perf", "T2", "T3");
            //     performance.measure("Foreach  lodash/map perf", "T3", "T4");
            //     //performance.measure("Iteration foreach perf native", "T2", "T1");

            //     var measures = performance.getEntriesByType("measure");
            //     measures.forEach(element => {
            //         console.log(element.name + ": " + element.duration);
            //     });
            //     performance.clearMarks();
            //     performance.clearMeasures();
            // }
        }

        Simulation.instance.sdk?.getSDK && Simulation.instance.sdk?.getSDK()?.renderer?.xr && (Simulation.instance.sdk.getSDK().renderer.xr.enabled = true);
    }

    highlightModel(objectID: string, meshes: THREE.Object3D[] | null) {
        this.outlineComponent.temporalOutlines.set(objectID, new TemporalOutlineElement(3000, meshes!));
    }

    public resetAllNodesPositions(): void { //TODO-ST
        // this.spaceModels().forEach(spaceModel => {
        // if (spaceModel.nodeRef?.userData) {
        //     let name = spaceModel.nodeRef?.userData?.nameToShow;
        //     let n = this.sceneLoader.getNodes().find(node => node.userData?.id == spaceModel?.id);
        //     !n && console.error(`[vars] spacemodel node not found for ${name} `);
        //     if (n && !_.isEqual(n.userData, spaceModel.nodeRef?.userData)) {
        //         console.log(`[vars] equality check for userData for ${name}: ${_.isEqual(n.userData, spaceModel.nodeRef?.userData)}`);
        //     }
        // } else {
        //     console.error(`[vars] spacemodel node userData not found  `);
        // }

        // });
        if (this.spaceModels()) {
            this.spaceModels().forEach(spaceModel => {
                let node = spaceModel.nodeRef as ISceneNode;

                // if (spaceModel.nodeRef?.userData) {
                //     let name = spaceModel.nodeRef?.userData?.nameToShow;
                //     let n = this.sceneLoader.getNodes().find(node => node.userData?.id == spaceModel?.id);
                //     !n && console.error(`[vars] spacemodel node not found for ${name} `);
                //     if (n && !_.isEqual(n.userData, spaceModel.nodeRef?.userData)) {
                //         console.log(`[vars] equality check for userData for ${name}: ${_.isEqual(n.userData, spaceModel.nodeRef?.userData)}`);
                //     }
                // } else {
                //     console.error(`[vars] spacemodel node userData not found  `);
                // }

                if ((node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) || (node.userData[UserDataProperties.type] === UserDataTypes.analogGauge)) {
                    var iot = Utils.GetNestThermostatComponent(node);
                    iot?.Deactivate();
                }

                // When objects move Drag in a lesson
                if (node?.unserializedUserData && UnserializedUserData.NodePos in node.unserializedUserData
                ) {
                    // if(!node.unserializedUserData[UnserializedUserData.StartPosition]){

                    switch (node.name) {
                        // case 'Multimeter':
                        // case 'Wire':
                        //     break;
                        default:
                            console.log(`[st] [nodes] resetting unserializedUserData nodepos for ${node?.userData.nameToShow}`,
                                node.unserializedUserData[UnserializedUserData.NodePos]
                            );
                            ISceneNodeExtensions.setPosition(node, node.unserializedUserData[UnserializedUserData.NodePos].position);
                            ISceneNodeExtensions.setRotation(node, node.unserializedUserData[UnserializedUserData.NodePos].quaternion);
                            break;
                    }
                }

                // Used when objects move via Move Rotate or Scale animation actions. Positions move relatively (?)
                if (node?.unserializedUserData && UnserializedUserData.StartPosition in node.unserializedUserData) {
                    let originalMeshes = Utils.FindAllMeshesAndLineSegments(node);
                    console.log(`[st] [nodes] 1 found unserializedUserData for ${node?.userData.nameToShow}`);

                    for (const meshTransformCollection of node.unserializedUserData[UnserializedUserData.StartPosition] as
                        ITransform[]) {
                        console.log(`[st] [nodes] 2 found unserializedUserData for ${node?.userData.nameToShow}`);
                        let m = originalMeshes?.find(m => m.uuid == meshTransformCollection.mesh.uuid);
                        m = m || originalMeshes?.find(m => (m.uuid == (meshTransformCollection.mesh as any)?.object?.uuid));

                        console.log(`[st] [nodes] 3 found unserializedUserData for ${node?.userData.nameToShow}`);
                        if (m) {
                            console.log(`[st] [nodes] 4 found unserializedUserData for ${node?.userData.nameToShow}`);
                            m.position.set(meshTransformCollection.position.x, meshTransformCollection.position.y, meshTransformCollection.position.z);
                            m.rotation.set(meshTransformCollection.rotation.x, meshTransformCollection.rotation.y, meshTransformCollection.rotation.z, 'XYZ');
                            m.scale.set(meshTransformCollection.scale.x, meshTransformCollection.scale.y, meshTransformCollection.scale.z);
                        }
                    }

                    // function notifyFirWireTerm(component: WireTerminal) {

                    //     const payLoadSent = {
                    //         "terminal": component.inputs.terminal,
                    //         "position": new THREE.Vector3(component.mesh.position.x, component.mesh.position.y, component.mesh.position.z)
                    //     }
                    //     if (component.mesh) {
                    //         component.notify(ComponentInteractionType.CLICK, payLoadSent)
                    //     }

                    // }

                    if (node.name === 'Multimeter') {
                        (node.components[9].inputs as any).text = '0 V';

                        // node.components[1].notify('repaint', {
                        //     component: node.components[1]
                        // });
                        // node.components[2].notify('repaint', {
                        //     component: node.components[2]
                        // })
                        // node.components[7].notify('repaint', {
                        //     component: node.components[7]
                        // })
                        // node.components[8].notify('repaint', {
                        //     component: node.components[8]
                        // })
                        // notifyFirWireTerm(node.components[1] as WireTerminal);
                        // notifyFirWireTerm(node.components[2] as WireTerminal);
                        // notifyFirWireTerm(node.components[7] as WireTerminal);
                        // notifyFirWireTerm(node.components[8] as WireTerminal);

                        (node.components[0] as WireComponent).repaintWire("StartTerminal",
                            (node.components[0] as WireComponent).inputs.lineWidth, undefined,
                            undefined, node.components[1] as WireTerminal);

                        (node.components[6] as WireComponent).repaintWire("StartTerminal",
                            (node.components[6] as WireComponent).inputs.lineWidth, undefined,
                            undefined, node.components[8] as WireTerminal);

                        // (node.components[0] as WireComponent).notify('repaint_start', {
                        //     component: (node.components[1] as WireTerminal)
                        // });
                        // (node.components[6] as WireComponent).notify('repaint_end', {
                        //     component: (node.components[8] as WireTerminal)
                        // })

                    }

                    if (node.name === 'Wire') {

                    }
                }
                if (node?.unserializedUserData?.customProps) {
                    node.userData.customProps = node?.unserializedUserData?.customProps;
                }
                node.unserializedUserData = {};


            });
        }
    }

    async scenePreProcess(node: ISceneNode | SceneNode, dbJSON: any = null): Promise<void> {
        //node.start();

        if (!node.userData) {
            node.userData = {};
        }

        // if (!node.unserializedUserData) {
        node.unserializedUserData = {};
        // }

        let customSceneNode: boolean = false;

        if (areNodesSpatialThinkSDK()) {
            customSceneNode = true;
            try { //TODO-ST what happens when this node is stopped and started again? will we lose the ref?
                (node as SceneNode).customComponents[0].root.userData = {
                    ...(node as SceneNode).customComponents[0].root.userData,
                    nodeRef: node
                }
            } catch (e: any) {
                console.error('[st] [stsdk] error saving nodeRef in custom component', dbJSON?.userData)
            }
        }

        if (dbJSON == null) {
            let quaternionStart = new THREE.Quaternion();
            QuaternionExtensions.assign(quaternionStart, node.quaternion);
            node.userData['quaternionStart'] = JSON.stringify(quaternionStart);
            node.userData[UserDataProperties.ClickEventActionList] = [];
            //node.userData["show"] = [];
            //node.userData["hide"] = [];

            if (node.userData[UserDataProperties.hasColorProperty]) {
                node.userData[UserDataProperties.customColorProperty] = '#44ffffaa';
            }

            if (node.userData[UserDataProperties.hasBorderProperty]) {
                node.userData[UserDataProperties.borderColorProperty] = '#000000ff';
            }

            if (UserDataProperties.executeOnceAndRemove in node.userData) {
                let scaleOnce = node.userData[UserDataProperties.executeOnceAndRemove][NodeDataProperties.scale];
                node.userData[UserDataProperties.executeOnceAndRemove] = {};

                ISceneNodeExtensions.setScaleFromAny(node, scaleOnce);
            }

            if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
                node.userData[UserDataProperties.textProperty] = 'Add a helpful cue!';
                node.userData[UserDataProperties.customColorProperty] = '#00000080';
                node.userData[UserDataProperties.borderColorProperty] = '#F8DB1CFF';
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.boundedBox ||
                node.userData[UserDataProperties.type] === CompatabilityUserDataTypes.boundedBox) {
                node.userData[UserDataProperties.customColorProperty] = '#65E35050';
                node.userData[UserDataProperties.borderColorProperty] = '#00000000';
                node.userData[UserDataProperties.type] = UserDataTypes.boundedBox;
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.highlightBorder) {
                //node.userData[UserDataProperties.customColorProperty] = "#65E35050"
                //node.userData[UserDataProperties.borderColorProperty] = "#00000000"
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                node.userData[UserDataProperties.customColorProperty] = '#CF5300FF';
                node.userData[UserDataProperties.borderColorProperty] = '#ffa500FF';
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.imageRenderer) {
                node.userData[UserDataProperties.customColorProperty] = '#ffffffff';
                node.userData[UserDataProperties.borderColorProperty] = '#ff000000';
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.arrowFlow) {
                node.userData[UserDataProperties.customColorProperty] = '#48E670ff';
            }

        } else {

            try {
                let position = JSON.parse(dbJSON.position);
                let rotation = JSON.parse(dbJSON.quaternion);
                let scale = JSON.parse(dbJSON.scale);

                ISceneNodeExtensions.setPositionFromAny(node, position);

                let newRotation = new THREE.Quaternion(rotation._x, rotation._y, rotation._z, rotation._w);//.setFromEuler(new Euler(45, 0, 0, "XYZ"));
                //let newRotation = new Quaternion().setFromEuler(new Euler(45, 0, 0, "XYZ"));
                ISceneNodeExtensions.setRotation(node, newRotation);
                ISceneNodeExtensions.setScaleFromAny(node, scale);

                if (node.userData[UserDataProperties.type] === UserDataTypes.wire) {
                    // (node.components[1] as WireTerminal).updateTerminalWidthBasedOnWire(node.userData.wireWidth);
                    // (node.components[2] as WireTerminal).updateTerminalWidthBasedOnWire(node.userData.wireWidth);
                    (node.components[1] as WireTerminal).alignTerminalWithWire(node.position);
                    (node.components[2] as WireTerminal).alignTerminalWithWire(node.position);
                }

                // let wireTerminalCheck = node.userData.nameToShow.split(" ")[0];

                // if (wireTerminalCheck === "StartTerminal" || wireTerminalCheck === "EndTerminal") {
                //     let parentNode = Simulation.instance.sceneLoader.findNodeByID(node.userData.srcObjectId);
                //     if (parentNode) {
                //         const positionUpdate = new THREE.Vector3();
                //         positionUpdate.subVectors(node.position, parentNode.position);
                //         (parentNode.components[0] as WireComponent).updateCurveOutline(positionUpdate, wireTerminalCheck, parseInt(parentNode.userData.wireWidth));
                //         (node.components[0] as WireTerminal).alignTerminalWithWire(node.position, parentNode.position);
                //         (node.components[0] as WireTerminal).colorWireTerminal(wireTerminalCheck);
                //         (node.components[0] as WireTerminal).updateTerminalWidthBasedOnWire(parentNode.userData.wireWidth);
                //     }
                // }

                // if (node.name === "Wire") {
                //     let terminalNodeIds = node.userData.markers;

                //     for (let index in terminalNodeIds) {
                //         let terminalNode = Simulation.instance.sceneLoader.findNodeByID(terminalNodeIds[index]);
                //         if (terminalNode) {
                //             const positionUpdate = new THREE.Vector3();
                //             positionUpdate.subVectors(terminalNode.position, node.position);
                //             let tx = parseInt(index) === 0 ? "StartTerminal" : "EndTerminal";
                //             (node.components[0] as any).updateCurveOutline(positionUpdate, tx, parseInt(node.userData.wireWidth));
                //             (terminalNode.components[0] as WireTerminal).alignTerminalWithWire(terminalNode.position, node.position);
                //             (terminalNode.components[0] as WireTerminal).updateTerminalWidthBasedOnWire(node.userData.wireWidth);
                //             (terminalNode.components[0] as WireTerminal).colorWireTerminal(tx as any);

                //             // positionUpdate.copy(terminalNode.position);
                //         }
                //     }
                // }
            } catch (e: any) {
                console.error('[st] [stsdk] error setting position, rotation, scale in preprocess', dbJSON?.userData);
            }
            Utils.ApplyAllPropertiesFromJSONtoJSON(node.userData, dbJSON.userData);

            if (UserDataProperties.overrideUserData in node.userData) {
                Utils.ApplyAllPropertiesFromJSONtoJSON(node.userData, node.userData[UserDataProperties.overrideUserData]);
            }
        }

        if (UserDataProperties.localPosition in node.userData) {

            if (customSceneNode) {
                let tempMeshInstance = ((node as SceneNode).customComponents[0] as any);

                tempMeshInstance.inputs.localPosition.x = Number.parseFloat(node.userData[UserDataProperties.localPosition].x);
                tempMeshInstance.inputs.localPosition.y = Number.parseFloat(node.userData[UserDataProperties.localPosition].y);
                tempMeshInstance.inputs.localPosition.z = Number.parseFloat(node.userData[UserDataProperties.localPosition].z);
            } else {
                let tempMeshInstance = (node.components[0] as any);

                tempMeshInstance.inputs.localPosition.x = Number.parseFloat(node.userData[UserDataProperties.localPosition].x);
                tempMeshInstance.inputs.localPosition.y = Number.parseFloat(node.userData[UserDataProperties.localPosition].y);
                tempMeshInstance.inputs.localPosition.z = Number.parseFloat(node.userData[UserDataProperties.localPosition].z);
            }
        }

        if (UserDataProperties.rotationAxis in node.userData) {

            if (customSceneNode) {
                let tempMeshInstance = ((node as SceneNode).customComponents[0] as any);

                tempMeshInstance.inputs.rotationAxis.x = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].x);
                tempMeshInstance.inputs.rotationAxis.y = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].y);
                tempMeshInstance.inputs.rotationAxis.z = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].z);
                (tempMeshInstance as RotateToggleComponent).prepareClips();
            } else {
                let tempMeshInstance = (node.components[1] as any);

                tempMeshInstance.inputs.rotationAxis.x = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].x);
                tempMeshInstance.inputs.rotationAxis.y = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].y);
                tempMeshInstance.inputs.rotationAxis.z = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].z);
                (tempMeshInstance as RotateToggle).prepareClips();
            }
        }

        if (UserDataProperties.rotationRange in node.userData) {
            if (customSceneNode) {
                let tempCustomSceneNode = node as SceneNode;
                let tempMeshInstance = tempCustomSceneNode.customComponents[0] as RotateToggleComponent;
                tempMeshInstance.inputs.rotationRange = node.userData[UserDataProperties.rotationRange].split(',');
            } else {
                let tempMeshInstance = (node.components[1] as any);
                tempMeshInstance.inputs.rotationRange = node.userData[UserDataProperties.rotationRange].split(',');
            }
        }

        if (UserDataProperties.rotationSpeed in node.userData) {
            if (customSceneNode) {
                // let tempCustomSceneNode = node as SceneNode;
                // let tempMeshInstance = tempCustomSceneNode.customComponents[0] as RotateToggleComponent;
                // tempMeshInstance.inputs.rotationRange = node.userData[UserDataProperties.rotationRange].split(',');
            } else {
                let tempMeshInstance = (node.components[1] as any);
                tempMeshInstance.inputs.rotationSpeed = node.userData[UserDataProperties.rotationSpeed];
            }
        }

        if (UserDataProperties.type in node.userData) {
            if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
                ((node as any).components[4] as PlaneRenderer).canvasText = (node as any).components[6];
                ((node as any).components[4] as PlaneRenderer).canvasRenderer = (node as any).components[5];
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                //this.propertiesPanel.verifyInputSourceForIOT(node);
                //VariableTypeAllowedValues.updateInputSourceForNestNode(node, this.variables!);
            }
        }

        if (!!node.userData[UserDataProperties.lock]) {
            // console.log(`%c[st] [debug] ${node.userData[UserDataProperties.nameToShow]} is ${node.userData[UserDataProperties.lock]}`,'color: red;');
            Utils.DisableCollidersOnNode(node);
        }
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.objLoader':
                case 'mp.daeLoader':
                case 'mp.fbxLoader':
                case 'mp.gltfLoader':
                case 'mp.highlightBox':
                case 'mp.planeRenderer':
                case 'st.plateRenderer':
                case 'mp.onOffButton':
                case 'st.imageRenderer':
                case 'st.customGLTFLoader':
                case 'st.fireParticleSystem':
                case 'st.waterParticleSystem':
                case 'mp.spotlightmodel':
                case 'mp.flowmodel':
                case 'mp.dropzone':
                case 'mp.wire':
                case 'mp.wireTerminal':
                case "mp.multimeter":

                    try {
                        const tempHoverSpy = new HoverSpy(component, node);
                        component.spyOnEvent(tempHoverSpy);

                        const tempAddObjectClickSpy = new AddObjectClickSpy(component, node);
                        component.spyOnEvent(tempAddObjectClickSpy);

                        const tempDragBeginObjectClickSpy = new DragBeginObjectSpy(component, node);
                        component.spyOnEvent(tempDragBeginObjectClickSpy);

                        const tempDragEndObjectClickSpy = new DragEndObjectSpy(component, node);
                        component.spyOnEvent(tempDragEndObjectClickSpy);

                        const tempDragObjectClickSpy = new DragObjectSpy(component, node);
                        component.spyOnEvent(tempDragObjectClickSpy);

                    } catch (e: any) {
                        console.error(e);
                    }
                    // const tempGlowHoverSpy = new GlowHoverSpy(component, node);
                    //         component.spyOnEvent(tempGlowHoverSpy);

                    if (component.componentType === 'mp.objLoader') {
                        let objs = Utils.FindAllMeshesAndLineSegments(node);
                        if (objs) {
                            Utils.PatchMeshesMaterialUVs(objs);
                        }
                    }
                    try {
                        if (node.userData[UserDataProperties.type] === UserDataTypes.InteriorDesignModel) {
                            if (component.componentType === 'mp.objLoader' ||
                                component.componentType === 'mp.gltfLoader' ||
                                component.componentType === 'mp.daeLoader' ||
                                component.componentType === 'st.customGLTFLoader' ||
                                component.componentType === 'mp.wire') {
                                const tempPoseResetOnInit = new PoseResetOnInit(node);

                                const tempGlowHoverSpy = new GlowHoverSpy(component, node);
                                component.spyOnEvent(tempGlowHoverSpy);
                            }
                        } else if (node.userData[UserDataProperties.type] !== UserDataTypes.leverToggle) {

                            const tempGlowHoverSpy = new GlowHoverSpy(component, node);
                            component.spyOnEvent(tempGlowHoverSpy);
                        }

                    } catch (e: any) {
                        console.error(e);
                    }


                    break;
            }
        }

        if (node.userData[UserDataProperties.hasColorProperty]) {
            let colorHexAndAlpha = Utils.SeparateHexFromAlpha(node.userData[UserDataProperties.customColorProperty]);
            Simulation.instance.propertiesPanel.colorSaveQueueScheduler.addQueueElement(new ChangeNodeColorPassThrough(colorHexAndAlpha[0], colorHexAndAlpha[1], false, node, false), true);
        }

        if (UserDataProperties.hasBorderProperty in node.userData) {
            Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(
                new ChangeBooleanPropertyPassThrough(
                    node.userData[UserDataProperties.hasBorderProperty], UserDataProperties.hasBorderProperty, node, false,
                ));

            if (UserDataProperties.borderColorProperty in node.userData) {
                let colorHexAndAlpha = Utils.SeparateHexFromAlpha(node.userData[UserDataProperties.borderColorProperty]);
                Simulation.instance.propertiesPanel.colorSaveQueueScheduler.addQueueElement(new ChangeNodeColorPassThrough(colorHexAndAlpha[0], colorHexAndAlpha[1], true, node, false), true);
            }
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
            Simulation.instance.propertiesPanel.changeTextOfNode(
                new ChangeTextPanelTextPassThrough(
                    node.userData[UserDataProperties.textProperty], Utils.GetUD_NumberValue(node.userData, UserDataProperties.fontSize), null, node, false,
                ));
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.arrowFlow) {
            Simulation.instance.propertiesPanel.changeFlowRadius(
                new ChangeTextPassThrough(
                    node.userData[UserDataProperties.flowRadius], node, false,
                ));
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.imageRenderer) {

            if ((node as any).customSceneNode !== undefined) {
                let tempSceneNode = node as SceneNode;
                (tempSceneNode.customComponents[0] as ImageRendererComponent).inputs.textureSource = node.userData[UserDataProperties.textureSource];
            } else {
                ((node.components[0] as any) as ImageRenderer).inputs.textureSource = node.userData[UserDataProperties.textureSource];
            }
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.analogGauge) {

            if ((node as any).customSceneNode !== undefined) {
                //not implemented yet
            } else {
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.textureSource = node.userData[UserDataProperties.textureSource];
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.minReading = node.userData[UserDataProperties.minReading];
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.maxReading = node.userData[UserDataProperties.maxReading];
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.rotationDelta = node.userData[UserDataProperties.rotationDelta];
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.initialReadingOffset = node.userData[UserDataProperties.initialReadingOffset];
                ((node.components[3] as any) as AnalogGaugeComponent).inputs.hideDigitalReading = node.userData[UserDataProperties.hideDigitalReading];
            }
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.wire) {
            if ((node as any).customSceneNode !== undefined) {
                //not implemented yet
            } else {
                ((node.components[0] as any) as WireComponent).inputs.lineWidth = node.userData[UserDataProperties.wireWidth];
                const startTerminalPosition = node.userData[UserDataProperties.startTerminalPosition];
                const endTerminalPosition = node.userData[UserDataProperties.endTerminalPosition];
                // const midTerminalPosition = node.userData[UserDataProperties.midTerminalPosition];
                if (startTerminalPosition && endTerminalPosition) {
                    // (node.components[1] as WireTerminal).inputs.localPosition = new THREE.Vector3(startTerminalPosition.x, startTerminalPosition.y, startTerminalPosition.z);
                    // (node.components[2] as WireTerminal).inputs.localPosition = new THREE.Vector3(endTerminalPosition.x, endTerminalPosition.y, endTerminalPosition.z);
                    (node.components[1] as WireTerminal).updateMeshPosition(new THREE.Vector3(startTerminalPosition.x, startTerminalPosition.y, startTerminalPosition.z));
                    (node.components[2] as WireTerminal).updateMeshPosition(new THREE.Vector3(endTerminalPosition.x, endTerminalPosition.y, endTerminalPosition.z));
                    // (node.components[3] as WireTerminal).updateMeshPosition(new THREE.Vector3(midTerminalPosition.x, midTerminalPosition.y, midTerminalPosition.z));

                    // ((node.components[0] as any) as WireComponent).repaintWire(node.components[1], "StartTerminal", node.userData[UserDataProperties.wireWidth], node, startTerminalPosition);
                    // ((node.components[0] as any) as WireComponent).repaintWire(node.components[2] as any, "EndTerminal", node.userData[UserDataProperties.wireWidth], node, endTerminalPosition);
                }
            }
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.multimeter) {
            if ((node as any).customSceneNode !== undefined) {
                //not implemented yet
            } else {
                ((node.components[0] as any) as WireComponent).inputs.lineWidth = node.userData[UserDataProperties.wireWidth];
                ((node.components[6] as any) as WireComponent).inputs.lineWidth = node.userData[UserDataProperties.wireWidth];
                const startTerminal1Position = node.userData[UserDataProperties.startTerminal1Position];
                const endTerminal1Position = node.userData[UserDataProperties.endTerminal1Position];
                const startTerminal2Position = node.userData[UserDataProperties.startTerminal2Position];
                const endTerminal2Position = node.userData[UserDataProperties.endTerminal2Position];

                if (startTerminal1Position && endTerminal1Position && startTerminal2Position && endTerminal2Position) {
                    // (node.components[1] as WireTerminal).inputs.localPosition = new THREE.Vector3(startTerminalPosition.x, startTerminalPosition.y, startTerminalPosition.z);
                    // (node.components[2] as WireTerminal).inputs.localPosition = new THREE.Vector3(endTerminalPosition.x, endTerminalPosition.y, endTerminalPosition.z);
                    (node.components[1] as WireTerminal).updateMeshPosition(new THREE.Vector3(startTerminal1Position.x, startTerminal1Position.y, startTerminal1Position.z));
                    (node.components[2] as WireTerminal).updateMeshPosition(new THREE.Vector3(endTerminal1Position.x, endTerminal1Position.y, endTerminal1Position.z));

                    (node.components[7] as WireTerminal).updateMeshPosition(new THREE.Vector3(startTerminal2Position.x, startTerminal2Position.y, startTerminal2Position.z));
                    (node.components[8] as WireTerminal).updateMeshPosition(new THREE.Vector3(endTerminal2Position.x, endTerminal2Position.y, endTerminal2Position.z));

                    ((node.components[0] as any) as WireComponent).repaintWire("StartTerminal", node.userData[UserDataProperties.wireWidth], startTerminal1Position, node, node.components[1]);
                    ((node.components[0] as any) as WireComponent).repaintWire("EndTerminal", node.userData[UserDataProperties.wireWidth], endTerminal1Position, node, node.components[2]);

                    ((node.components[6] as any) as WireComponent).repaintWire("StartTerminal", node.userData[UserDataProperties.wireWidth], startTerminal2Position, node, node.components[7]);
                    ((node.components[6] as any) as WireComponent).repaintWire("EndTerminal", node.userData[UserDataProperties.wireWidth], endTerminal2Position, node, node.components[8]);
                }
            }
        }

        // if (node.userData[UserDataProperties.type] === UserDataTypes.wireTerminal) {
        //     if (Simulation.instance.srcObjectIdForInternalObjects) {

        //         let parentNode: ISceneNode | undefined = store.getState().home.spaceModels.get(Simulation.instance.srcObjectIdForInternalObjects)?.nodeRef;

        //         if (parentNode && parentNode.userData.type === "wire") {
        //             let pointTerminals = (parentNode.components[0] as any).getStartAndEndPoints();
        //             if (parentNode.userData[UserDataProperties.markers] == undefined) {
        //                 parentNode.userData[UserDataProperties.markers] = [];
        //                 node.position.x = parentNode.position.x + pointTerminals[0].x;
        //                 node.position.y = parentNode.position.y + pointTerminals[0].y;
        //                 node.position.z = parentNode.position.z + pointTerminals[0].z;

        //                 node.userData[UserDataProperties.nameToShow] = "StartTerminal " + parentNode.userData[UserDataProperties.nameToShow];
        //                 node.userData[UserDataProperties.srcObjectId] = parentNode.userData.id;
        //                 (node.components[0] as WireTerminal).colorWireTerminal("StartTerminal" as any);
        //                 (node.components[0] as WireTerminal).alignTerminalWithWire(node.position, parentNode.position);
        //                 // node.userData.type = UserDataTypes.internalObject;
        //                 await NodeStorage.storeNode(node);

        //                 parentNode.userData.markers.push(node.userData.id);
        //                 await NodeStorage.storeNode(parentNode);//.then(async () => {
        //                 if (parentNode) {
        //                     //endTerminal
        //                     await NodeStorage.loadNode('WireTerminal', undefined, null);

        //                     let endTerminalNode = Simulation.instance.sceneLoader.getLastNodeAdded();
        //                     endTerminalNode.position.x = parentNode.position.x + pointTerminals[2].x;
        //                     endTerminalNode.position.y = parentNode.position.y + pointTerminals[2].y;
        //                     endTerminalNode.position.z = parentNode.position.z + pointTerminals[2].z;

        //                     endTerminalNode.userData[UserDataProperties.nameToShow] = "EndTerminal " + parentNode.userData[UserDataProperties.nameToShow];
        //                     endTerminalNode.userData[UserDataProperties.srcObjectId] = parentNode.userData.id;
        //                     (endTerminalNode.components[0] as WireTerminal).colorWireTerminal("EndTerminal" as any);
        //                     (endTerminalNode.components[0] as WireTerminal).alignTerminalWithWire(endTerminalNode.position, parentNode.position);
        //                     // node.userData.type = UserDataTypes.internalObject;
        //                     await NodeStorage.storeNode(endTerminalNode)//.then(() => {

        //                     // if (parentNode) {
        //                     parentNode.userData.markers.push(endTerminalNode.userData.id);
        //                     await NodeStorage.storeNode(parentNode);
        //                     Simulation.instance.srcObjectIdForInternalObjects = '';
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    public InitializationComplete(): boolean {

        //animate gltf models
        //         mixer = new THREE.AnimationMixer(model);
        //         gltf.animations.forEach((clip) => {
        //             mixer.clipAction(clip).play();
        //         });

        // do this onTick
        // mixer.update(delta);
        return this.initializationComplete;
    }

    public updateFireWaterObjects(boxVisible: boolean) {

        if (Simulation.instance.sceneLoader) {

            let fireWaterModels = Simulation.instance.sceneLoader.findNodesByName(['Fire', 'Water Spray']);

            fireWaterModels.forEach(m => {
                (m?.components && m?.components[0] as any).inputs.boxVisible = boxVisible;
            });
        }
    }

    public startOutlineGlow(object: THREE.Object3D) {

        // if (UserDataProperties.skinnedNode in this.node.userData) {
        //   meshes = [(this.node.userData[UserDataProperties.skinnedNode] as THREE.Group)];
        // } else {
        // let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(object);
        // Simulation.instance.outlineComponentColor2.addEphemeralMeshes(meshes, object.id);
        // }

        // Apply the highlighting effect
        // setHighlight(object, true);
        this.applyHighlightMaterial(object);


        // After 1 second, remove the highlighting effect
        setTimeout(() => {
            this.resetOriginalMaterial(object);
        }, 1000);

        setTimeout(() => {
            this.originalMaterials.size > 0 && EulerIntegrator.instance?.scene && this.resetOriginalMaterial(EulerIntegrator.instance?.scene);
            this.originalMaterials.size > 0 && console.log('materials left after resetting the whole scene', [...this.originalMaterials.keys()].map(m => object.getObjectByProperty('uuid', m)));
        }, 4000);

    }

    public stopOutlineGlow(object: THREE.Object3D) {
        Simulation.instance.outlineComponentColor2.removeEphemeralMeshes(object.id);
    }

    public applyHighlightMaterial(object: THREE.Object3D): void {
        if (object.type === 'Mesh') {
            // Save the original material
            (object as THREE.Mesh).material && this.originalMaterials.set(object.uuid, (object as THREE.Mesh).material);

            // Apply the highlight material
            const highlightMaterial = new THREE.MeshBasicMaterial({ color: 0x00f000 });
            (object as THREE.Mesh).material = highlightMaterial;
        }

        // Recur for children
        object.children.forEach(child => this.applyHighlightMaterial(child));
    }

    public resetOriginalMaterial(object: THREE.Object3D): void {
        if (object.type === 'Mesh') {
            // Reset to the original material
            const originalMaterial = this.originalMaterials.get(object.uuid);
            if (originalMaterial !== undefined) {
                (object as THREE.Mesh).material = originalMaterial;
                this.originalMaterials.delete(object.uuid);
            }
        }

        // Recur for children
        object.children.forEach(child => this.resetOriginalMaterial(child));
    }
}
