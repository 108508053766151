//import { Mesh, MeshBasicMaterial } from "three";
import * as THREE from 'three';
import { PlaneRenderer } from '../components/meshComponents/basic/PlaneRenderer';
import { OrientedBox } from '../components/meshComponents/HighlightBox';
import { ISceneNode, SceneComponent } from '../SubSystems/sceneManagement/SceneComponent';
import { MeshUserDataProperties, UserDataProperties, UserDataTypes } from '../SubSystems/ui-interop/PropertiesPanel';
import { CanvasText } from '../components/CanvasText';
import { Object3D, Vector3 } from 'three';
import { NestThermostat } from '../components/meshComponents/NestThermostat';
import { RotateToggle } from '../components/tiny/RotateToggle';
import { ToggleComponent } from '../components/tiny/ToggleComponent';
import Emitter from '../SubSystems/ParticleEngine/Emitter';
import { FireParticleSystemComponent } from '../components/ParticleSystem/FireParticleSystemComponent';
import { WaterParticleSystemComponent } from '../components/ParticleSystem/WaterParticleSystemComponent';
import { Profiler } from 'react';
import Simulation from '../SubSystems/core/Simulation';
import { AnalogGaugeComponent } from '../components/AnalogGaugeComponent';
import { SceneNode } from 'CustomSdk/Mimick/SceneNode';
import { BaseComponent } from 'CustomSdk/Components/BaseComponent';
import { areNodesSpatialThinkSDK } from 'modules/home/SpaceDetail/utils';
import { Dropzone } from '../components/meshComponents/Dropzone';
import { RaycastSystem } from '../../../../CustomSdk/Systems/RaycastSystem';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';
import { saveAs } from 'file-saver';
import { WireTerminal } from '../components/Wire/WireTerminal';

export class MatrixWithSeparatedBasis {
    constructor(public matrix: THREE.Matrix4, public right: THREE.Vector3, public up: THREE.Vector3, public forward: THREE.Vector3) {
    }
}

type Descripted<T> = {
    [K in keyof T]: {
        readonly id: T[K];
        readonly description: string;
    }
}[keyof T]

export default class Utils {
    public static isPrimitive(test: any): boolean {
        return test !== Object(test);
    }

    public static getColorAsVector(color: THREE.Color): THREE.Vector3 {
        return new THREE.Vector3(color.r, color.g, color.b);
    }

    public static getVector3AndDuration(inputString: string, defaultValue: number = 0): [THREE.Vector3, number] {
        if (!inputString) {
            return [new THREE.Vector3(0, 0, 0), 0.1];
        }

        let newPosition = inputString.split(',');
        let newPositionVector = new THREE.Vector3(defaultValue, defaultValue, defaultValue);

        if (newPosition.length > 0) {
            let value = Number.parseFloat(newPosition[0]);
            if (!isNaN(value)) {
                newPositionVector.x = value;
            }
        }

        if (newPosition.length > 1) {
            let value = Number.parseFloat(newPosition[1]);
            if (!isNaN(value)) {
                newPositionVector.y = value;
            }
        }

        if (newPosition.length > 2) {
            let value = Number.parseFloat(newPosition[2]);
            if (!isNaN(value)) {
                newPositionVector.z = value;
            }
        }
        let duration: number = 1000;
        if (newPosition.length > 3) {
            let value = Number.parseFloat(newPosition[3]);
            if (!isNaN(value)) {
                duration = value * 1000;
            }
        }

        return [newPositionVector, duration];
    }

    // public static SimpleClone(x: any): any {
    //     return JSON.parse(JSON.stringify(x));
    // }

    public static MakeRotationKeyFrameTrack(time: number, startRotation: THREE.Quaternion, endRotation: THREE.Quaternion): THREE.QuaternionKeyframeTrack {
        return new THREE.QuaternionKeyframeTrack(
            '.quaternion',
            [0, time],
            [startRotation.x, startRotation.y, startRotation.z, startRotation.w,
            endRotation.x, endRotation.y, endRotation.z, endRotation.w],
            THREE.InterpolateLinear,
        );
    }

    public static PlayAnimation(mixer: THREE.AnimationMixer, clip: THREE.AnimationClip, object3D?: Object3D): void {
        const action: THREE.AnimationAction = mixer.clipAction(clip, object3D);
        action.loop = THREE.LoopOnce;
        action.clampWhenFinished = true;
        action.play();
    }

    public static RemoveTrailingZeros(value: number) {
        return parseFloat(value.toString());
    }

    public static RoundedRectShape(width: number, height: number, radius: number): THREE.Shape {
        const roundedRectShape = new THREE.Shape();
        radius *= 0.001;

        roundedRectShape.moveTo(0, radius);
        roundedRectShape.lineTo(0, height - radius);
        roundedRectShape.quadraticCurveTo(0, height, radius, height);
        roundedRectShape.lineTo(0 + width - radius, height);
        roundedRectShape.quadraticCurveTo(width, height, width, height - radius);
        roundedRectShape.lineTo(width, radius);
        roundedRectShape.quadraticCurveTo(width, 0, width - radius, 0);
        roundedRectShape.lineTo(radius, 0);
        roundedRectShape.quadraticCurveTo(0, 0, 0, radius);

        return roundedRectShape;
    }

    public static reorientNormalToIdealSurface(n: THREE.Vector3) {
        n.multiplyScalar(10);
        n.roundToZero();
        n.normalize();
    }

    public static MakeBasisWithNormalAndTwoCardinalVectors(
        newNormal: THREE.Vector3,
        cardinalUpVector: THREE.Vector3 = new THREE.Vector3(0, 1, 0),
        cardinalRightVector: THREE.Vector3 = new THREE.Vector3(0, 1, 0),
    ): MatrixWithSeparatedBasis {

        let surfaceUpVector = new THREE.Vector3(newNormal.x, newNormal.y, newNormal.z);
        Utils.reorientNormalToIdealSurface(surfaceUpVector);


        let rotationBetweenSurfaceNormalAndWorldUpVector = new THREE.Quaternion().setFromUnitVectors(cardinalUpVector, surfaceUpVector);
        var rotatedCardinalRightVector = cardinalRightVector.clone().applyQuaternion(rotationBetweenSurfaceNormalAndWorldUpVector);

        let surfaceForwardVector = new THREE.Vector3();
        surfaceForwardVector.crossVectors(rotatedCardinalRightVector, surfaceUpVector);
        surfaceForwardVector.normalize();

        let surfaceRightVector = new THREE.Vector3();
        surfaceRightVector.crossVectors(surfaceUpVector, surfaceForwardVector);
        surfaceRightVector.normalize();

        let matrix = new THREE.Matrix4();
        matrix.makeBasis(surfaceRightVector, surfaceUpVector, surfaceForwardVector);

        return new MatrixWithSeparatedBasis(matrix, surfaceRightVector, surfaceUpVector, surfaceForwardVector);
    }

    /*
        public static ExtractModelDisplayName(modelName:string, modelID:string):string {
            let nameToShow = modelName.replace("/assets/libraryObjectScenes/", "").replace(".json", "");
            nameToShow = nameToShow == "nest" ? "Gauge": nameToShow;
            nameToShow = nameToShow ==  "CrankButton" ? "Lever": nameToShow;
            nameToShow = nameToShow == "arrowPBRDae" ? "Arrow": nameToShow;
            nameToShow = nameToShow == "boundedBox" ? "Box": nameToShow;
            nameToShow = nameToShow + "-" + modelID.slice(0, Math.min(3, modelID.length));
            return nameToShow;
        }*/

    public static GetUD_NumberValue(userData: { [key: string]: any }, key: string, alt: number | null = null): number | null {
        if (key in userData) {
            return userData[key];
        }

        return alt;
    }

    public static SetMeshesVisibility(meshes: THREE.Object3D[], visiblity: boolean): void {
        if (meshes) {
            for (let mesh of meshes) {
                if (mesh.type === 'Mesh' || mesh.type == 'SkinnedMesh') {
                    let tempMesh = (mesh as THREE.Mesh);

                    if (MeshUserDataProperties.arrowFlow in tempMesh.userData) {
                        //This ignores setting visibility for the cyclinder in the flow component
                    } else {
                        if (Array.isArray(tempMesh.material)) {
                            for (let material of tempMesh.material) {
                                material.visible = visiblity;
                            }
                        } else {
                            (tempMesh.material as THREE.Material).visible = visiblity;
                        }
                    }
                } else if (mesh.type === 'LineSegments') {
                    let tempLineSegments = (mesh as THREE.LineSegments);

                    if (Array.isArray(tempLineSegments.material)) {
                        for (let material of tempLineSegments.material) {
                            material.visible = visiblity;
                        }
                    } else {
                        (tempLineSegments.material as THREE.Material).visible = visiblity;
                    }
                }
            }
        }
    }

    public static PatchMeshesMaterialUVs(meshes: THREE.Object3D[]): void {
        if (meshes) {
            for (let mesh of meshes) {
                if (mesh.type === 'Mesh') {
                    let tempMesh = (mesh as THREE.Mesh);
                    if (Array.isArray(tempMesh.material)) {
                        for (let material of tempMesh.material) {
                            //material.visible = visiblity;
                            var tempMat = (material as THREE.MeshBasicMaterial);
                            if (tempMat.map) {
                                tempMat.map!.wrapS = THREE.RepeatWrapping;
                                tempMat.map!.wrapT = THREE.RepeatWrapping;
                            }


                        }
                    } else {
                        //(tempMesh.material as THREE.Material).visible = visiblity;
                        var tempMat = (tempMesh.material as THREE.MeshBasicMaterial);
                        if (tempMat.map) {
                            tempMat.map!.wrapS = THREE.RepeatWrapping;
                            tempMat.map!.wrapT = THREE.RepeatWrapping;
                        }
                    }
                }
            }
        }
    }

    public static SetMeshesColor(meshes: THREE.Object3D[], color: number | string): void {
        // console.log(`[st] [3d] meshcolor is ${color}`);
        if (meshes) {
            // let colorNumber: number;
            // if (typeof color === "string")
            //     colorNumber = parseInt(color);
            // else
            //     colorNumber = color;
            for (let mesh of meshes) {
                if (mesh.type === 'Mesh') {
                    var tempMesh = mesh as THREE.Mesh;
                    (tempMesh.material as THREE.MeshBasicMaterial).color = new THREE.Color(color);
                    // console.log((tempMesh.material as THREE.MeshBasicMaterial).color);
                } else if (mesh.type === 'LineSegments') {
                    /*
                    TODO: Have to support independent coloring of LineSegments through bitmasks
                    let tempLineSegments = (mesh as THREE.LineSegments);
                    (tempLineSegments.material as THREE.LineBasicMaterial).color.set(color);
                    */
                }
            }
        }
    }

    public static GetHexWithAlpha(colorHex: string, alpha: number): string {
        let alphaString = Math.ceil(alpha * 255).toString(16);
        alphaString = alphaString.length < 2 ? '0' + alphaString : alphaString;
        return colorHex + alphaString;
    }

    public static SimpleColorHexToString(colorHexNumber: number): string {
        let colorString = '#' + colorHexNumber.toString(16).toUpperCase();
        return colorString;
    }

    public static SeparateHexFromAlpha(colorHex: string): [string, number] {
        //#ffaabbcc
        if (!colorHex) {
            return ['#ffaabb', 0.5];
        }
        let hexString = colorHex.substr(0, 7);
        let alpha = parseInt(colorHex.substr(7, 2), 16) / 255;
        //return colorHex + Math.ceil(alpha * 255).toString(16);
        return [hexString, alpha];
    }

    public static GetHighlightBoxComponent(node: ISceneNode): OrientedBox | Dropzone | null {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.highlightBox':
                    return component as OrientedBox;
                    break;
                case 'mp.dropzone':
                    return component as Dropzone;
                    break;
            }
        }

        return null;
    }

    public static GetRotateToggleComponent(node: ISceneNode): RotateToggle | AnalogGaugeComponent | null {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.rotateToggle':
                    return component as RotateToggle;
                    break;
            }
        }

        return null;
    }

    public static SetVisibility(visible: boolean, node: ISceneNode, meshes: THREE.Object3D[] | null = null) {
        // console.log(node.name);

        if (areNodesSpatialThinkSDK()) {
            if (!!visible) {
                (node as SceneNode).customComponents?.forEach(component => {
                    component.show();
                });
            } else {
                (node as SceneNode).customComponents?.forEach(component => {
                    component.hide();
                });
            }
        } else {
            if (!meshes) {
                meshes = Utils.FindAllMeshesAndLineSegments(node);
            }
            if (['Fire', 'Water Spray'].includes(node.name)) {
                // if (!meshes) {
                let emitters = Utils.GetEmitters(node);
                if (emitters) {
                    for (const emitter of emitters) {
                        emitter.setVisible(visible);
                    }
                    // console.log('[st] visibility', visible, node.userData.nameToShow)
                }
            } else {
                Utils.SetMeshesVisibility(meshes!, visible);
            }
            let toggleComponent = Utils.GetToggleComponent(node);
            if (toggleComponent) {
                toggleComponent.initializeToggleComponent();
            }
        }
    }

    public static GetEmitters(node: ISceneNode): Emitter[] | null {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            return Utils.GetComponentEmitters(component);
        }

        return null;
    }

    public static GetComponentEmitters(component: SceneComponent): Emitter[] | null {

        switch (component.componentType) {
            case 'st.fireParticleSystem':
                return (component as FireParticleSystemComponent).getEmitters();
                break;
            case 'st.waterParticleSystem':
                return (component as WaterParticleSystemComponent).getEmitters();
                break;
        }
        return null;

    }

    public static GetToggleComponent(node: ISceneNode): ToggleComponent | null {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.ToggleComponent':
                    return (component as ToggleComponent);
                    break;
            }
        }

        return null;
    }

    public static GetModelToggleComponent(node: ISceneNode): ToggleComponent | null {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.ToggleComponent':
                    return component as ToggleComponent;
                    break;
            }
        }

        return null;
    }

    public static GetNestThermostatComponent(node: ISceneNode): NestThermostat | AnalogGaugeComponent | null {

        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.nestThermostat':
                    return component as NestThermostat;
                    break;
                case 'mp.AnalogGauge':
                    return component as AnalogGaugeComponent;
                    break;
            }
        }

        return null;
    }

    public static GetPlaneRendererComponent(node: ISceneNode): PlaneRenderer | null {

        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.planeRenderer':
                    return component as PlaneRenderer;
                    break;
            }
        }

        return null;
    }

    public static GetAllCanvasTextComponents(node: ISceneNode): CanvasText[] | null {
        let canvasTextArray: CanvasText[] | null = null;
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.canvasText':
                    //return component as PlaneRenderer;
                    if (component) {
                        if (canvasTextArray == null) {
                            canvasTextArray = [];
                        }
                        canvasTextArray.push(component as CanvasText);
                    }
                    break;
            }
        }

        return canvasTextArray;
    }

    public static ApplyAllPropertiesFromJSONtoJSON(targetJSON: { [key: string]: any }, dbJSON: { [key: string]: any }): void {
        for (var prop in dbJSON) {
            targetJSON[prop] = dbJSON[prop];
        }
    }

    public static GetAllMeshesAndLineSegmentsInObject3D(target: THREE.Object3D,
        meshes: Map<string, THREE.Object3D> = new Map()
    ): THREE.Object3D[] {
        // let meshes: Object3D[] = [];

        for (let i = 0; i < (target.children ? target.children.length : 0); i++) {
            if (target.children[i].type === 'Group' ||
                target.children[i].type === 'Object3D') {
                Utils.GetAllMeshesAndLineSegmentsInObject3D(target.children[i], meshes);
                // ms.forEach(m => meshes.set(m.uuid, m));
            } else {
                if (target.children[i].type === 'Mesh') {
                    meshes.set(target.children[i].uuid, target.children[i] as THREE.Mesh);
                    Utils.GetAllMeshesAndLineSegmentsInObject3D(target.children[i], meshes);
                } else if (target.children[i].type === 'SkinnedMesh') {
                    meshes.set(target.children[i].uuid, target.children[i] as THREE.SkinnedMesh);
                    Utils.GetAllMeshesAndLineSegmentsInObject3D(target.children[i], meshes);
                } else if (target.children[i].type === 'LineSegments') {
                    meshes.set(target.children[i].uuid, target.children[i] as THREE.LineSegments);
                    Utils.GetAllMeshesAndLineSegmentsInObject3D(target.children[i], meshes);
                }
            }
        }

        return Array.from(meshes.values());
    }

    public static enumToDescriptedArray<T>(enumeration: T, separatorRegex: RegExp = /_/g): Descripted<T>[] {
        return (Object.keys(enumeration) as Array<keyof T>)
            .filter(key => isNaN(Number(key)))
            .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
            .map(key => ({
                id: enumeration[key],
                description: String(key).replace(separatorRegex, ' '),
            }));
    }

    public static Find3DRootOfNode(node: ISceneNode): THREE.Object3D | null {
        if (!node) {
            console.error('IMPORTANT: You\'ve searched for meshes on a node that doens\'t exist');
            return null;
        }
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {

            for (var prop in component) {
                if ((component as any)[prop]) {
                    if ((component as any)[prop].hasOwnProperty('type')) {
                        //if((component as any)[prop])
                        if ((component as any)[prop]['type'] === 'Object3D') {
                            return (component as any)[prop] as THREE.Object3D;
                        } else if ((component as any)[prop]['type'] === 'Group') {
                            return (component as any)[prop] as THREE.Object3D;
                        }
                    }
                }
            }
        }

        return null;
    }


    public static DisableCollidersOnNode(node: ISceneNode): void {

        if (areNodesSpatialThinkSDK()) {
            let customNode = node as SceneNode;

            for (const component of customNode.customComponents) {
                if (component.outputs.hasOwnProperty('collider')) {
                    if (component.outputs.collider != null) {
                        let collider = component.outputs.collider;
                        (component as any).outputs.oldCollider = collider;
                        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.removeRayWorldObject(collider);
                        component.outputs.collider = null;

                    }
                }
            }
        } else {
            const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
            // console.log(`%c[st] [debug]  disabling colliders for  ${node.userData.nameToShow}`, 'color: red;');
            // console.log(`%c[st] [debug]  disabling colliders for  ${node.userData.nameToShow}`,'color: red;');
            for (const component of componentIterator) {
                if (component.outputs.hasOwnProperty('collider')) {
                    if (component.outputs.collider != null) {
                        let collider = component.outputs.collider;
                        (component as any).oldCollider = collider;
                        component.outputs.collider = null;
                    }
                }
            }
        }
    }

    public static getCollidersOnNode(node: ISceneNode): any[] {

        let colliders: any[] = [];

        if (!node.userData[UserDataProperties.lock]) {
            const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();

            for (const component of componentIterator) {
                // Simulation.instance.componentNodeMap.set(component.outputs.objectRoot.uuid, node);
                if (component.outputs.hasOwnProperty('collider')) {
                    let collider = (component as any).outputs.collider;
                    collider && node && colliders.push(collider);
                    collider && node && Simulation.instance.colliderNodeMap.set(collider.uuid, node);
                }
                if (component.outputs.hasOwnProperty('oldCollider')) {
                    let collider = (component as any).outputs.oldCollider;
                    component.outputs.collider = collider;
                    collider && node && colliders.push(collider);
                    collider && node && Simulation.instance.colliderNodeMap.set(collider.uuid, node);
                }
            }
        }
        return colliders;
    }


    public static EnableCollidersOnNode(node: ISceneNode): void {


        if (!node.userData[UserDataProperties.lock]) {
            if (areNodesSpatialThinkSDK()) {
                let customNode = node as SceneNode;

                for (const component of customNode.customComponents) {
                    if (component.outputs.hasOwnProperty('oldCollider')) {
                        let collider = (component as any).outputs.oldCollider;
                        component.outputs.collider = collider;
                        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(collider);
                    }
                }
            } else {
                // console.log(`%c[st] [debug]  enabling colliders for  ${node.userData.nameToShow}`, 'color: red;');
                // console.log(`%c[st] [debug]  enabling colliders for  ${node.userData.nameToShow}`,'color: red;');
                const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();

                for (const component of componentIterator) {
                    if (component.hasOwnProperty('oldCollider')) {
                        let collider = (component as any).oldCollider;
                        component.outputs.collider = collider;
                    }
                }
            }
        } else {
            // console.log(`%c[st] [debug] not enabling colliders for  ${node.userData.nameToShow}`, 'color: red;');
            // console.log(`%c[st] [debug] not enabling colliders for  ${node.userData.nameToShow}`,'color: red;');
        }
    }

    public static FindComponentMeshesAndLineSegments(component: SceneComponent,
        meshes: Map<string, THREE.Object3D> = new Map()): THREE.Object3D[] {

        // let meshes: THREE.Object3D[] = [];
        // let meshes: Map<string, THREE.Object3D> = new Map();
        if ((component as any)['ignoreInMeshFindPopulation']) {
            //console.log("this should be ignored");
            //We ignore meshes with this property set, because they're perpetually invisible
        } else {
            for (var prop in component) {
                if ((component as any)[prop]) {
                    if ((component as any)[prop].hasOwnProperty('type')) {
                        let ms = Utils.GetAllMeshesAndLineSegmentsInObject3D((component as any)[prop]);
                        ms.forEach(m => meshes.set(m.uuid, m));
                        // if(component.componentType === 'mp.wireTerminal') {

                        if ((component as any)[prop].type === 'Mesh') {
                            let m = (component as any)[prop] as THREE.Mesh;
                            meshes.set(m.uuid, m);
                        }
                        // }
                    }
                }
            }
        }

        return Array.from(meshes.values());
    }

    public static FindComponentMeshesAndLineSegmentsST(component: BaseComponent): THREE.Object3D[] {
        let meshes: THREE.Object3D[] = [];
        if ((component as any)['ignoreInMeshFindPopulation']) {
            //console.log("this should be ignored");
            //We ignore meshes with this property set, because they're perpetually invisible
        } else {
            // for (var prop in component) {
            //     if ((component as any)[prop]) {
            //         if ((component as any)[prop].hasOwnProperty('type')) {
            //             meshes = meshes.concat(Utils.GetAllMeshesAndLineSegmentsInObject3D((component as any)[prop]));
            //         }
            //     }
            // }

            meshes = meshes.concat(Utils.GetAllMeshesAndLineSegmentsInObject3D(component.root));
        }

        return meshes;
    }


    public static FindAllMeshesAndLineSegments(node: ISceneNode | SceneNode): THREE.Object3D[] | null {//TODO-ST
        if (!node) {
            console.error('IMPORTANT: You\'ve searched for meshes on a node that doens\'t exist');
            return null;
        }
        let meshes: Map<string, THREE.Object3D> = new Map();

        if (!areNodesSpatialThinkSDK()) {
            const componentIterator: IterableIterator<SceneComponent> = (node as ISceneNode).componentIterator();
            for (const component of componentIterator) {
                let ms = Utils.FindComponentMeshesAndLineSegments(component);
                ms.forEach(m => meshes.set(m.uuid, m));

            }
        } else {
            // const componentIterator: IterableIterator<SceneComponent> = (node as SceneNode).componentIterator();
            for (const component of (node as SceneNode).customComponents) {
                let ms = Utils.FindComponentMeshesAndLineSegmentsST(component);
                ms.forEach(m => meshes.set(m.uuid, m));
            }
        }

        // var uniqueMeshes = meshes.filter(function (elem, index, self) {
        //     return index === self.indexOf(elem);
        // });

        if (meshes.size < 1) {
            let emitters = Utils.GetEmitters(node);
            if (emitters) {
                // for (const emitter of emitters) {
                // emitter.setVisible(visible);
                // }
                return emitters;
            }
            return null;
        }

        return Array.from(meshes.values());
    }

    /*
    public static FindAllEdgeGeometry(node:ISceneNode):THREE.EdgesGeometry[] | null
    {
        if(!node) {
            console.error("IMPORTANT: You've searched for EdgesGeometry on a node that doens't exist");
            return null;
        }
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        let edges:THREE.EdgesGeometry[] = [];
        for (const component of componentIterator) {

            for (var prop in component) {
                if((component as any)[prop]) {
                    if((component as any)[prop].hasOwnProperty('type')) {
                        edges = edges.concat(Utils.GetAllMeshesInObject3D((component as any)[prop]));
                    }
                }
            }
        }

        var uniqueEdges = edges.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })


        if(uniqueMeshes.length < 1) {
            return null;
        }

        return uniqueMeshes;
    }*/

    public static checkAllNodesLoadingStatus() {


        let loaded = true;
        // for (const node of Simulation?.instance?.scene?.getNodes()) {
        //     // let node = model.nodeRef;
        //     const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();

        //     for (const component of componentIterator) {
        //         if (!component.hasLoaded){
        //             loaded = false; //TODO  optimize by adding hasLoaded property to the node
        //         }
        //     }
        // }

        console.log(`[st] loading ALL components complete ${loaded}`);

    }

}


export function stringify(x: any): string {
    return JSON.stringify(x, JSONCircularReplacer());
}

export const JSONCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: object | null) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}

export function downloadBase64File(contentBase64: any, fileName: string) {
    // window.location.href
    const linkSource = 'data:image/jpg;base64,' + contentBase64;
    // <a download="FILENAME.EXT" href="data:image/png;base64,asdasd...">Download</a>


    // const linkSource = `data:application/pdf;base64,${contentBase64}`;
    console.log('screenshot linkSource', linkSource);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    // downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();

    // const linkSource = `data:application/pdf;base64,${contentBase64}`;
    // console.log('screenshot linkSource', linkSource);
    // const downloadLink = document.createElement('a');
    // document.body.appendChild(downloadLink);

    // downloadLink.href = linkSource;
    // downloadLink.target = '_self';
    // downloadLink.download = fileName;
    // downloadLink.click();
}

export const downloadBase64Data = (base64String: string, fileName: string) => {
    let file = convertBase64ToFile(base64String, fileName);
    file && saveAs(file, fileName);
}

const convertBase64ToFile = (base64String: string, fileName: string) => {
    let arr = base64String.split(',');
    if (arr && arr.length > 0) {
        let v = arr[0].match(/:(.*?);/);
        if (v && v.length > 1) {
            let mime = v[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let uint8Array = new Uint8Array(n);
            while (n--) {
                uint8Array[n] = bstr.charCodeAt(n);
            }
            let file = new File([uint8Array], fileName, { type: mime });
            return file;
        }
    }
    return '';
}

export function makeObjectNameUnique(name: string, existingNames: string[]): string {
    let uniqueName = name;
    let counter = 1;

    while (existingNames.includes(uniqueName)) {
        uniqueName = `${name} ${counter}`;
        counter++;
    }

    return uniqueName;
}

export function getCustomProps(nodeUserDataCustomProps: string) {
    if (!nodeUserDataCustomProps) {
        return {};
    }

    let regex = /\s*([^=,\n]+)\s*=\s*([^=,\n]+)[,\n]?\s*/g;
    let matches = (nodeUserDataCustomProps).match(regex) || [];
    let kvPairs: { [key: string]: string } = {};

    matches.forEach((match) => {
        let parts = match.split('=');
        let key = parts[0].trim();
        let value = parts[1].trim();
        kvPairs[key] = value;
    });
    return kvPairs;
}

export function updateCustomProps(nodeUserDataCustomProps: any, customPropName: string, customPropValue: any) {
    let kvPairs = getCustomProps(nodeUserDataCustomProps);
    kvPairs[customPropName] = customPropValue;

    // Convert the object back into a string
    let customPropsString = '';
    for (let k in kvPairs) {
        customPropsString += `${k} = ${kvPairs[k]}\n`;
    }
    return customPropsString;
}

export function updateComponentPositionFromWorld(component: SceneComponent, position: THREE.Vector3): void {

    switch (component.componentType) {
        case 'mp.wireTerminal':
            const parent = ((component as WireTerminal).mesh as THREE.Mesh).parent;
            if (parent) {
                const localIntersection = parent.worldToLocal(position);
                ((component as any).mesh as THREE.Mesh).position.copy(localIntersection);
                (component.inputs as any).localPosition = localIntersection;
            }
            break;
        default:
            console.error("updateComponentPositionFromWorld not implemented for this component", component.componentType);;
            break;
    }
}


export function updateSubObjectPositionFromWorld(subObject: THREE.Object3D, position: THREE.Vector3): void {

    // const parent = ((component as WireTerminal).mesh as THREE.Mesh).parent;
    // if (parent) {
    //     const localIntersection = parent.worldToLocal(position);
    //     ((component as any).mesh as THREE.Mesh).position.copy(localIntersection);
    //     (component.inputs as any).localPosition = localIntersection;
    // }

    subObject.position.copy(position);

}

export function getEulerAnglesFromQuaternion(quaternion: THREE.Quaternion): THREE.Euler {
    let euler = new THREE.Euler();

    // The order 'XYZ' means the rotation is performed as follows:
    // 1. Yaw around y-axis
    // 2. Then pitch around x-axis
    // 3. Finally roll around z-axis
    euler.setFromQuaternion(quaternion, 'XYZ');

    return euler;
}
